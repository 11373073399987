.toast {
  max-width: none;
  font-size: initial;
  color: #098543;
  background-color: $c-gray-200;
  border-color: $c-gum-leaf;
  box-shadow: none;

  &--danger {
    border-color: $c-shilo;
    background-color: $c-gray-200;
    color: $c-red;
  }

  &--membership {
    width: 400px;
    position: fixed;
    top: 50px;
    right: 50px;
  }
}
