.contacts {
  &__policyUpdatedAlert {
    color: $policy-update-alert;
    background: $policy-update-alert-bg;
    border: 1px solid $policy-update-alert-border;
    border-radius: 4px;
  }

  &__download {
    @extend .policy__download;
  }

  &__updated {
    @extend .policy__updated;
  }

  &__cardTitle {
    margin-top: 3px !important;
    font-size: 18px;
  }

  &__copyOnEmail {
    line-height: 1.25;
  }
}

.contact-card {
  &__fullname {
    font-weight: 500;
  }

  &__label-copy {
    font-weight: 500;
  }
}
