.weekly-recurring-picker {
  @include media-breakpoint-up(md) {
    padding-top: 33px;
  }

  .custom-control {
    padding-left: 0;
  }

  label {
    display: inline;
  }

  .weekly-checkbox {
    display: none;
  }

  input + p {
    background-color: #fafafa;
    border: 1px solid #cacece;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
      inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    display: inline-block;
    position: relative;
    padding: 6px 12px;
    color: #2a9fd8;
  }

  label:active .weekly-checkbox + p,
  label:active .weekly-checkbox:checked + p {
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
      inset 0px 1px 3px rgba(0, 0, 0, 0.1);
  }

  label .weekly-checkbox:checked + p {
    background-color: #e9ecee;
    border: 1px solid #2a9fd8;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
      inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05),
      inset 15px 10px -12px rgba(255, 255, 255, 0.1);
    color: white;
    background-color: #2a9fd8;
    border-right: 0px;
  }

  .day-of-week {
    border-right: 0px;
    min-width: 41px;
    min-height: 41px;
    text-align: center;
  }

  .first-day {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    @extend .day-of-week;
  }

  .last-day {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border: 1px solid #cacece;
    @extend .day-of-week;
  }
}
