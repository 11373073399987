.suggestion {
  &-item {
    padding: 0.25rem 1rem;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.event-type-card {
  cursor: pointer;
}
