.logo {
  &__img {
    display: block;
    width: 156px;

    @include media-breakpoint-up(lg) {
      width: 230px;
    }
  }
}
