.venueInput {
  position: relative;

  .autocomplete-dropdown-container {
    right: 0;
  }

  .dropdown {
    &-item {
      transition: 0.2s color, 0.2s background;
      padding: 0.25rem 1rem;
      overflow: hidden;
      text-overflow: ellipsis;
      &.active {
        background: lighten($c-primary, 10%);
      }
    }
  }
}
