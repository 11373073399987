.dropzone {
  &__overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(255, 255, 255, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    &-inner {
      background: white;
      padding: 1rem;
      text-align: center;
      justify-content: center;
      border: 1px solid $c-primary;
      border-radius: 5px;
    }
  }
  &__wrapper {
    position: relative;
    height: 250px;
    border: 1px dashed $c-basicare-blue;
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: white;
    padding: 2rem;
    color: $c-tundora;
    transition: 0.2s border-color, 0.2s background;
    &.active {
      background: $c-gray-200;
      border-color: $c-basicare-blue-light-secondary;
    }
  }

  &__input-label {
    font-size: 1rem;
    color: $c-tundora;
    font-weight: normal;
  }

  &__preview {
    max-width: 500px;
    width: 100%;
    margin: auto;
  }

  &__preview-img {
    font-size: 5rem;
    color: $c-gray-400;
  }

  &__filename {
    font-size: 1rem;
    margin-bottom: 0.5rem;
    word-break: break-all;
  }

  &__done-icon {
    color: $c-primary;
    position: relative;
    top: -0.3rem;
  }

  &__reset {
    position: absolute;
    bottom: 1rem;
    left: 50%;
    transform: translate(-50%, 0);
    font-size: 1rem;
    padding: 0.75rem 1.5rem;
  }

  &__input-main {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      cursor: pointer;
    }
  }

  &__browse {
    position: absolute;
    top: calc(50% + 38px);
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
