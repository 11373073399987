.add-policy {
  display: flex;
  flex-direction: column;
  height: 100vh;

  iframe {
    height: 100%;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    max-width: map-get($grid-breakpoints, "lg") - 1px;
    border: none;
  }
}
