.popover {
  padding: 5px 2px 5px 5px;
}
// Hack to fix flickering popover bug from react bootstrap package
.bs-popover {
  &-right {
    left: 5px !important;
  }
  &-left {
    right: 5px !important;
  }
}

.bs-popover-auto {
  &[x-placement^="right"] {
    left: 5px !important;
  }
  &[x-placement^="left"] {
    right: 5px !important;
  }
}

.popover-content {
  font-size: inherit;
  padding: 1rem 1rem;
  min-width: 230px;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.24);
  background-color: $c-white;
  font-family: $font-family-raleway;
  color: $c-basicare-blue;
  border: solid 1px $c-basicare-blue;
  top: 60px !important;
  right: -20px !important;

  a {
    font-weight: normal;
  }

  .arrow {
    display: none;
  }

  &__fit-content {
    max-width: fit-content;
  }

  .popover-item {
    &__btn {
      font-weight: normal;
      padding: 0;
      white-space: nowrap;
      font-family: $font-family-raleway;
      color: $c-basicare-blue;
      font-size: 1rem;
      &:hover {
        color: $c-basicare-blue;
        text-decoration: underline;
      }
    }

    &__icon {
      font-size: 1rem;
      color: $c-basicare-blue;
      min-width: 25px;
      text-align: center;
    }
  }
}
