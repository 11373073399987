.collapsible {
  width: 100%;
  border-bottom: 1px solid $c-gray-500;
  padding-bottom: 1rem;
  margin-bottom: $spacing-s;

  &__heading {
    padding: 0;
    width: 100%;
    display: flex;
    height: 75px;
    align-items: flex-end;
    background-color: transparent;
    border: none;
    margin-bottom: 8px;
  }

  &__icon {
    color: $c-basicare-orange;
    margin-left: auto;
  }
}
