.disable-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 5000;
  display: flex;
  justify-content: center;
  align-items: center;

  &__inner {
    height: 150px;
    width: 175px;
    padding-top: 0.5rem;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.5);
  }
}
