h1,
.h1 {
  font-size: 1.5rem;
  font-weight: 500;
  @include media-breakpoint-up(md) {
    font-size: 2rem;
  }
}
h2,
.h2 {
  line-height: 1;
  font-size: 1.875rem;

  @include media-breakpoint-up(md) {
    font-size: 2.813rem;
  }
}
h3,
.h3 {
  line-height: 1.1;
  font-weight: 500;

  font-size: 1.25rem;

  @include media-breakpoint-up(md) {
    font-size: 1.875rem;
  }
}
h4,
.h4 {
  line-height: 1.5;
  font-weight: 400;
}
h5,
.h5 {
  line-height: 1.5;
  font-weight: 500;
}
h6,
.h6 {
  line-height: 1.63;
  font-weight: 500;
}

p {
  font-size: 16px;
}

.list-font {
  font-size: 13px;
  @include media-breakpoint-up(md) {
    font-size: 1rem;
  }
}

.bold-1 {
  font-size: 1rem;
  font-weight: 500;
}

.label-small {
  font-size: 0.875rem;
}

.label-20-px {
  font-size: 1.25rem;
}

.label-md-20-px {
  @include media-breakpoint-up(md) {
    font-size: 1.25rem !important;
  }
}

.line-height-17 {
  line-height: 1.7;
}

.line-height-1 {
  line-height: 1;
}

.c-primary {
  color: $c-primary !important;
}

.c-primary-2 {
  color: $c-basicare-primary-2 !important;
}

.c-secondary {
  color: $c-secondary !important;
}

.c-blue-secondary {
  color: $c-basicare-orange-secondary;
}

.c-gray-600 {
  color: $c-gray-600;
}

.form-control-lg {
  @include media-breakpoint-up(md) {
    font-size: 1rem;
  }
}

.font-15-17-20 {
  font-size: 15px;

  @include media-breakpoint-up(md) {
    font-size: 17px;
  }

  @include media-breakpoint-up(lg) {
    font-size: 20px;
  }
}

.font-20 {
  font-size: 1.25rem;
}

.font-30 {
  font-size: 1.875rem;
}

.text-underline {
  text-decoration: underline;
}

.text-line-through {
  text-decoration: line-through;
}
