.pl {
  &--30 {
    padding-left: 30px;
  }
}

.pt {
  &--43 {
    padding-top: 43px;
  }
}

.w {
  &-lg--auto {
    @include media-breakpoint-up(lg) {
      width: auto !important;
    }
  }
  &--70 {
    width: 70px;
  }
  &--150 {
    width: 150px;
  }
  &--100 {
    width: 100px;
  }
  &--50 {
    width: 50px;
  }
  &--170 {
    width: 170px;
  }
  &--250 {
    width: 250px;
  }
  &--280 {
    width: 280px;
  }
}
.mw {
  &--750 {
    max-width: 750px;
  }
  &--700 {
    max-width: 700px;
  }
  &--635 {
    max-width: 635px;
  }
  &--556 {
    max-width: 556px;
  }
  &--435 {
    max-width: 435px;
  }
  &--455 {
    max-width: 455px;
  }
  &--400 {
    max-width: 400px;
  }
  &--300 {
    max-width: 300px;
  }
  &--290 {
    max-width: 290px;
  }
  &--250 {
    max-width: 250px;
  }
  &--130 {
    max-width: 130px;
  }
  &--193 {
    max-width: 193px;
  }
  &--91 {
    max-width: 91px;
  }

  &-md {
    @include media-breakpoint-up(md) {
      &--400 {
        max-width: 400px;
      }
    }
  }

  &-xl {
    @include media-breakpoint-up(xl) {
      &--200 {
        max-width: 200px;
      }
    }
  }
}

.mh {
  &--hf {
    min-height: calc(100vh - 210px);
  }
  &--24 {
    min-height: 24px;
  }
  &--300 {
    min-height: 300px;
  }
}

.mt {
  &--14 {
    margin-top: 14px;
  }
  &--20 {
    margin-top: 20px;
  }
  &--40 {
    margin-top: 40px;
  }
  &--65 {
    margin-top: 65px;
  }
}

.mt-lg {
  @include media-breakpoint-up(lg) {
    &--14 {
      margin-top: 14px !important;
    }
    &--20 {
      margin-top: 20px;
    }
    &--40 {
      margin-top: 40px !important;
    }
  }
}

.mb {
  &--14 {
    margin-bottom: 14px;
  }
  &--20 {
    margin-bottom: 20px;
  }
  &--40 {
    margin-bottom: 40px;
  }
}

.ml {
  &--20 {
    margin-left: 20px;
  }
}

.ellipsis {
  text-overflow: ellipsis;

  /* Required for text-overflow to do anything */
  white-space: nowrap;
  overflow: hidden;
}

.row-highlight {
  background: $c-basicare-blue-5;
}

.color-blue-secondary {
  color: $c-basicare-blue !important;
}

.sticky-bottom {
  position: sticky;
  bottom: 0;
  z-index: 2;
}

.border-color-blue-secondary {
  border-color: $c-basicare-orange-secondary;
}

.cursor-default {
  cursor: default !important;
}

.cursor-pointer {
  cursor: pointer;
}

.undecorate-btn {
  border: none;
  background: none;
}

.h {
  &-100vh {
    height: 100vh;
  }
}

.bg-white {
  background: $c-white;
}

.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
}

.border-radius-0 {
  border-radius: 0 !important;
}

.border-radius-4 {
  border-radius: 4px !important;
}

.border-left-radius-0 {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.overflow-wrap-break-word {
  overflow-wrap: break-word;
}

.right-0 {
  right: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.gap-3 {
  gap: 1rem;
}
