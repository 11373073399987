.async-button {
  position: relative;
  &__spinner {
    display: none;
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  &__spinning-text {
    margin-left: 1rem;
  }

  &--spinning {
    .async-button__spinner {
      display: flex;

      .spinner-border {
        $spinner-size: 24px;
        height: $spinner-size;
        width: $spinner-size;
      }
    }

    .content {
      visibility: hidden;
    }
  }
}
