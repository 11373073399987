.sidebar-menu {
  background: $main-menu-bg;
  height: 100vh;
  width: 200px;
  display: flex;
  padding-top: 0;
  grid-area: sidebar;
  transition: width 0.5s;

  &:not(.show) {
    width: 72px;
  }

  &-collapse {
    width: 200px;
    position: relative;
    flex-basis: 100%;
    flex-grow: 1;
    padding: 0;
    color: $menu-item-font-color;

    & .main-menu {
      &__title {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 150px;
      }

      &__logo {
        transition: all 0.5s;
        // $logo-size: 84px;
        // height: $logo-size;
        width: 100%;
      }
    }

    & .menu-wrapper {
      padding-bottom: $spacing-l;
      height: calc(100vh - 82px);
      overflow-y: auto;
      overflow-x: hidden;
    }

    &:not(.show) {
      width: 72px;
      display: block;

      & .sidebar-menu-header {
        & .sidebar-menu-toggle {
          & span::before {
            content: "\F105";
            font-family: "Font Awesome 5 Pro", serif;
          }
        }
      }

      & .menu-wrapper {
        & .sidebar-menu-body {
          & .sidebar-menu-nav a {
            & span {
              display: none;
            }

            & i {
              display: block;
            }
          }
        }

        & button {
          font-size: 0;
          letter-spacing: 0;

          &:before {
            font-size: 18px;
            content: "\F08B";
            font-family: "Font Awesome 5 Pro", serif;
          }
        }
      }
    }

    & .sidebar-menu-header {
      // background: $main-menu-title-bg;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 82px;

      & .sidebar-menu-toggle {
        border: 1px solid $c-basicare-blue;
        color: $c-basicare-blue;
        background-color: $c-white;
        border-radius: 3px;
        position: absolute;
        height: 2rem;
        width: 2rem;
        padding: 0.1rem;
        right: -37px;
        top: 5px;
        z-index: 10;
        transition: all 0.5s;

        & span::before {
          content: "\F104";
          font-family: "Font Awesome 5 Pro", serif;
        }

        @extend .navbar-toggler;
      }
    }

    & .sidebar-menu-body {
      margin-bottom: 50px;

      & .sidebar-menu-nav a {
        position: relative;
        font-weight: 500;
        font-size: 0.75rem;
        padding-left: 15px;
        height: 41px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        color: $c-warm-black;
        font-family: $font-family-raleway;
        border: none;
        outline: none;
        background: $main-menu-bg;
        transition: color 0.5s, background-color 0.5s;
        margin-top: 8px;

        & i {
          font-size: 18px;
          width: 40px;
          text-align: center;
          display: none;
        }

        & .menu-container__item-underline {
          width: 43px;
          height: 1px;
          background-color: $c-warm-black;
          position: absolute;
          bottom: 0;
        }

        &:hover,
        &:focus {
          color: $c-primary;
          text-decoration: none;
          background-color: $c-white;

          & div {
            background-color: $c-basicare-blue;
          }
        }

        &.active {
          color: $c-primary;
          background-color: $c-white;

          & div {
            background-color: $c-basicare-blue;
          }
        }
      }
    }
  }
}
