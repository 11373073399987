// Recaptcha badge is hidden in most contexts
.grecaptcha-badge {
  visibility: hidden;
}
// Recaptcha badge is shown on public pages
.page-login .grecaptcha-badge {
  visibility: visible;
}
.page-request-reset-password .grecaptcha-badge {
  visibility: visible;
}
