.btn {
  font-family: $font-family-hind;
  &:focus {
    box-shadow: none;
  }

  &-primary,
  &-outline-primary,
  &-outline-secondary,
  &-danger {
    text-transform: uppercase;
    letter-spacing: 3.4px;
  }

  &-primary {
    &.disabled,
    &:disabled {
      background: $c-basicare-blue-light;
      border-color: $c-basicare-blue-light;
      color: $c-gray-600;
      opacity: 1;
    }
  }

  &-outline-tertiary {
    color: $c-tundora;
    font-weight: normal;
  }

  &-link {
    color: $c-basicare-orange-secondary;
    font-weight: 500;
    font-family: $font-family-raleway;
    font-size: 1rem;

    &:hover {
      color: $c-basicare-orange-secondary;
      text-decoration-thickness: 2px;
    }

    &--black {
      color: $c-warm-black;

      &:hover {
        color: $c-warm-black;
      }
    }

    &--delete {
      text-transform: capitalize;
      padding: 0;
      font-weight: 500;
    }
  }

  &--edit {
    font-weight: 500;
    font-size: 1rem;
    padding: 0;
  }

  &-page {
    width: 40px;
    height: 40px;
    border: 1px solid $c-gray-400;
    border-radius: 0;
    border-left: 0;
    font-family: $font-family-raleway;
    font-weight: 400;
    color: $c-warm-black;

    &--selected,
    &:hover {
      text-decoration: none;
      color: white;
      background: $c-primary;
    }

    &--nav {
      font-size: 18px;
      &:focus {
        background: white;
        color: $c-basicare-blue;
      }
    }

    &--left {
      border-left: 1px solid $c-gray-400;
    }
    &--right {
      border-left: 0;
      background: white;
    }
  }

  &--link-underlined {
    color: $c-dark;
    text-decoration: underline;
  }

  &--radio {
    height: 87px;
    display: flex;
    align-items: center;
    border-color: $c-gray-400;
    line-height: 1.5;
    transform: translate(0, 0);
    box-shadow: none;
    transition: 0.2s transform, 0.2s box-shadow;
    font-family: $font-family-raleway;

    &:hover {
      transform: translate(0, -5px);
      background: white;
      border-color: $c-gray-300;
      color: $c-gray-600;
      box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1);
      cursor: pointer;
    }

    @include touch-hover() {
      box-shadow: none;
      transform: none;
    }

    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    .show > &.dropdown-toggle {
      border: solid 1px $c-basicare-blue;
      background-color: $c-basicare-blue-5;
      color: $c-basicare-blue;
      font-weight: 500;
    }
  }

  &--form {
    border: 1px solid #ced4da;
    font-size: 24px;
    line-height: 1;
  }

  &--breadcrumb {
    padding: 0;
    margin-top: -3px;
    &.small {
      font-size: 80%;
    }
  }

  &--alertClose {
    color: $policy-update-alert;

    &:hover {
      color: inherit;
    }
  }

  &--lg {
    height: 3rem;
    padding-left: 1.375rem;
    padding-right: 1.375rem;
  }
}
