.venueInput,
.search-field {
  position: relative;
  & input {
    height: 50px;
  }
  & .fa-search {
    position: absolute;
    left: 13px;
    top: 68%;
    transform: translate(0, -50%);
    background-color: transparent;
    color: $c-gray-400;
    font-size: 1.5rem;
  }
  & .btn {
    position: absolute;
    right: 5px;
    top: 68%;
    transform: translate(0, -50%);
    border: none;
    font-size: 1.5rem;
    line-height: 1;
    color: $c-gray-600;
    z-index: 5;

    &:hover {
      color: $c-gray-600;
    }

    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
}

.search-field {
  & &__icon {
    top: 50%;
  }

  & &__clear {
    top: 50%;
  }
}
