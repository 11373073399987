.header {
  background: $c-gray-200;
  height: 60px;
  width: 100%;
  top: 0;
  position: fixed;
  z-index: 1055; // modal z-index is 1050

  &__help-text {
    font-size: 13px;
    line-height: 1;
    @include media-breakpoint-up(md) {
      font-size: unset;
    }
  }
  &__help-num {
    font-size: 1.25rem;
    color: $c-dark;
    font-weight: 500;
  }
}
