.content {
  &__header {
    border-bottom: $policies-border;
    padding: 1rem 4rem;
    min-width: 650px;
    position: sticky;
    top: 0;
    z-index: 10;
    background: white;

    &--autoWidth {
      width: auto;
    }
  }

  &__body {
    padding: 2rem 4rem;
    min-width: 650px;
  }
}
