.venue-notes {
  &__history {
    height: 20em;
    overflow: auto;
    overflow-y: scroll;
  }
  &__history-text {
    line-height: 1.6rem;
  }
  &__history-footer {
    @extend .policy__updated;
    color: $c-gray-600;
  }
}
