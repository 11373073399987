.form-error {
  color: $c-danger;
  font-size: 80%;
  margin-bottom: 0.25rem;
  + input,
  + input:required,
  + textarea,
  + textarea:required,
  + input[type="text"]:required,
  + input[type="tel"]:required,
  + input[type="number"]:required,
  + .label__subtext + input,
  + select,
  + .input-group input {
    border-color: $c-danger !important;
    box-shadow: none;
  }

  &__label,
  a {
    color: $c-danger;
  }
}

.venue-code-form-error {
  color: $c-danger;
  border-color: $c-danger;
}

.purchase-error {
  @extend .form-error;
  margin-top: 1rem;
}
