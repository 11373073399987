.label {
  font-weight: 600;
  margin-bottom: 0.25rem;
  font-size: 16px;

  &__subtext {
    display: flex;
    align-items: center;
    color: $c-dark;
    margin-bottom: 0.5rem;

    &--inline {
      display: inline;
      margin: 0 0.5rem;
      font-size: 80%;
      font-weight: 400;
      font-style: italic;
    }
  }
}
