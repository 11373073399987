.address-field-group {
  .input-group-append {
    margin-left: 0;
  }
  &__autocomplete-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $c-white;
    color: $c-basicare-neutral-2;
    border-top-right-radius: 4px !important;
    border-bottom-right-radius: 4px !important;

    &:hover,
    &:focus {
      color: $c-basicare-neutral-2;
      text-decoration: none;
    }
  }
}
