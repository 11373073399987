.component {
  margin-top: 25px;
  margin-bottom: 25px;

  &__block {
    border: solid 1px #dde3e8;
    border-radius: 3px;
    padding: 25px;
  }

  &.disabled {
    opacity: 0.3;
    pointer-events: none;
  }

  &.enabled {
    opacity: 1;
    pointer-events: all;
  }
}
