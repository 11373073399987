.modal {
  &--width-lg {
    @include media-breakpoint-up(sm) {
      width: 90%;
      max-width: 1224px;
    }
  }

  // backdrop & positioning to allow header clicks
  &-dialog {
    top: 60px;
  }

  &-title {
    font-weight: 500;
  }

  .modal-header .close {
    padding: 0.5rem 1rem;
    font-weight: 400;
    font-size: 2.5rem;
  }
}

.reassignedVenues {
  background: $c-gray-200;
  height: 56px;
  padding: 15px 12px 17px 12px;
}
