.alert {
  &__close {
    font-size: 1.2rem;
    color: $c-basicare-orange;
  }
  &-info {
    color: $c-basicare-orange;
    border-color: $c-basicare-orange-secondary;
    background-color: $c-basicare-orange-10;
  }
  &-success {
    color: $c-green;
    border-color: $c-gum-leaf;
    background-color: $c-gray-200;
  }
  &-danger {
    color: $c-basicare-orange;
    border-color: $c-basicare-orange-secondary;
    background-color: $c-basicare-orange-10;
  }
  &-warning {
    color: $c-basicare-orange;
    border-color: $c-basicare-orange-secondary;
    background-color: $c-basicare-orange-10;
    &__link {
      color: $c-basicare-orange;
      text-decoration: underline;
      font-weight: normal;
      vertical-align: baseline;
      padding: 0;
      font-family: $font-family-raleway !important;
    }
  }
}

.referralToast {
  position: fixed;
  bottom: 80px;
  right: 15px;
  z-index: 999;

  &__body {
    color: #fff;
    background-color: $referral-toast-bg;
    border-color: $referral-toast-bg;
    box-shadow: 0 3px 8px 1px rgba(0, 0, 0, 0.4);
  }
}
