.icon {
  &-wrapper {
    height: 55px;
    width: 44px;
    min-width: 44px;
    background-repeat: no-repeat;
    background-size: contain;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 0.4rem 0.7rem;

    &--lg {
      height: 55px;
      width: 44px;
      @include media-breakpoint-up(lg) {
        height: 78px;
        width: 62px;
        padding: 0 0.7rem 0.7rem;
      }
    }

    &__icon-image {
      width: 100%;
    }
  }

  &--insurance {
    width: 42px;
  }

  &--large {
    font-size: 25px;
  }
}
